import { useState, useEffect, useCallback } from 'react';
import dbManager from '../../common/dbManager';
import './styles/poolTracker.scss';
import UserStats from '../UserStats/UserStats';
import AllUserStats from '../AllUserStats/AllUserStats';

const SCORE_TYPES = {
  BALL_DOWN_ON_BREAK: 'BALL_DOWN_ON_BREAK',
  POT_MISSED: 'POT_MISSED',
  POSITION_MISSED: 'POSITION_MISSED',
  FOUL: 'FOUL',
  ADJUSTMENT: 'ADJUSTMENT'
};

const PoolTracker = () => {
  const [showStats, setShowStats] = useState(false);
  const [userStats, setUserStats] = useState([]);
  const [ballDownOnBreak, setBallDownOnBreak] = useState(1);
  const [potsMissed, setPotsMissed] = useState(0);
  const [positionsMissed, setPositionsMissed] = useState(0);
  const [fouls, setFouls] = useState(0);
  const [adjustments, setAdjustments] = useState(0);
  const [score, setScore] = useState(0);
  const [enteredUser, setEnteredUser] = useState('');
  const [user, setUser] = useState(undefined);

  const submitUsername = (e) => {
    e.preventDefault();
    setEnteredUser(e.currentTarget.elements.username.value);
  };
  
  const resetScores = () => {
    setBallDownOnBreak(1);
    setPotsMissed(0);
    setPositionsMissed(0);
    setFouls(0);
    setScore(0);
    setAdjustments(0);
  };

  const loadData = useCallback(async () => {
    if(enteredUser){
      const getUserResult = await dbManager.getUser(enteredUser);
      if(getUserResult?.data?.length){
        setUser(getUserResult.data[0]);
      }
      else {
        alert("User not found");
        resetScores();
        setEnteredUser('');
        setUser(undefined);
      }
    }

    if(user?.id){
      const getUserStats = await dbManager.getUserStats(user?.id);
      if(getUserStats?.data.length){
        const userStats = getUserStats.data;
        setUserStats(userStats);
      }
      else {
        setUserStats([]);
      }
    }
  }, [enteredUser, user?.id]);

  const saveData = async () => {
    const saveDataResult = await dbManager.saveMasterClearanceData(`{
      "username": "${user?.username}",
      "userId": "${user?.id}",
      "ballDownOnBreak": ${ballDownOnBreak},
      "potsMissed": ${potsMissed}, 
      "positionsMissed": ${positionsMissed}, 
      "fouls": ${fouls}, 
      "adjustments": ${adjustments}, 
      "score": ${score}
    }`);
    resetScores();

    return saveDataResult;
  };

  const updateScore = (scoreType, amount = 1) => {
    if(scoreType === SCORE_TYPES.BALL_DOWN_ON_BREAK){
      setBallDownOnBreak(amount);
    }
    else if(scoreType === SCORE_TYPES.POT_MISSED){
      setPotsMissed(potsMissed + amount);
    }
    else if(scoreType === SCORE_TYPES.POSITION_MISSED){
      setPositionsMissed(positionsMissed + amount);
    }
    else if(scoreType === SCORE_TYPES.FOUL){
      setFouls(fouls + amount);
    }
    else if(scoreType === SCORE_TYPES.ADJUSTMENT){
      if (window.confirm('Are you sure you wish to make a manual score adjustment?')){
        setAdjustments(adjustments + amount);
      }
    }
  };

  const submitScore = async () => {
    if (window.confirm('Are you sure you want to submit?')){
      const saveDataResult = await saveData();
      if(!saveDataResult){
        alert("Error saving data... Jamie is a dumb dumb");
      }
      loadData();
    }
  };

  const toggleShowStats = () => {
    setShowStats(!showStats);
  };

  useEffect(() => {
    loadData();
  }, [loadData]); 

  // Update score based on other values 
  useEffect(() => {
    setScore(ballDownOnBreak + potsMissed + positionsMissed + fouls + adjustments);
  }, [ballDownOnBreak, potsMissed, positionsMissed, fouls, adjustments]); 

  return (
    <div className="pool-tracker">
      {!user?.username ? (
        <div className="enter-username">
          <h3>Please enter your username:</h3>
          <form onSubmit={submitUsername}>
            <input type="text" name="username" />
            <br />
            <button type="submit" class="login-button">Submit</button>
          </form>
        </div>
      ) : (
        <>
          <div className="header">
            <div className="header-left">
              <h3>User: {user.username}</h3>
              <button
                onClick={() => toggleShowStats()}
                className="show-stats"
              >
                {showStats ? 'Hide All Stats' : 'Show All Stats'}
              </button>
            </div>
            <UserStats userStats={userStats} />
          </div>
          {showStats && <AllUserStats userStats={userStats} />}
          <div className="tracker-buttons">

          <div className="button-box">
            <button 
              onClick={() => updateScore(SCORE_TYPES.BALL_DOWN_ON_BREAK, ballDownOnBreak ? 0 : 1)}
              className="ball-down-on-break"
            >
              Ball Down On Break? 
              <br/>
              <div className="yes-or-no">{ballDownOnBreak ? 'No...' : 'Yes!'}</div> 
              <span className="press-to-toggle">(Press to Toggle)</span>
              <br />
              <span class="mini-score">(Currently: {ballDownOnBreak})</span>
            </button>
          </div>

            <div className="button-box">
              <button 
                onClick={() => updateScore(SCORE_TYPES.POT_MISSED)}
                className="pot-missed"
              >
                Pot(s) Missed (+1)
                <br />
                <span class="mini-score">(Currently: {potsMissed})</span>
              </button>
            </div>

            <div className="button-box">
              <button 
                onClick={() => updateScore(SCORE_TYPES.POSITION_MISSED)}
                className="position-missed"
              >
                Position(s) Missed (+1)
                <br />
                <span class="mini-score">(Currently: {positionsMissed})</span>
              </button>
            </div>

            <div className="button-box">
              <button
                onClick={() => updateScore(SCORE_TYPES.FOUL)}
                className="foul"
              >
                Foul/Foul Break(s) (+1)
                <br />
                <span class="mini-score">(Currently: {fouls})</span>
              </button>
            </div>
          </div>

          <h3>Current Score:</h3>

          <div className="current-score">
            <div className="button-box">
              <button 
                onClick={() => updateScore(SCORE_TYPES.ADJUSTMENT, -1)} 
                className='adjustor-button'>
                  -
              </button>
            </div>

            <div className="score">{score}</div>

            <div className="button-box">
              <button 
                onClick={() => updateScore(SCORE_TYPES.ADJUSTMENT)} 
                className='adjustor-button'>
                  +
              </button>
            </div>
          </div>

          <div className="submit-section">
            <button className="submit-button"
              onClick={() => submitScore()} 
            >
              Submit
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default PoolTracker;
