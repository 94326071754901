import { useState, useEffect } from 'react';
import './styles/userStats.scss';
import prettyNumber from '../../common/prettyNumber.js';

const GROUP_SIZE = 20;

const UserStats = (props) => {
  const [averageTotal, setAverageTotal] = useState(0);
  const [averageCurrentTwenty, setAverageCurrentTwenty] = useState(0);
  const [averageLastTwenty, setAverageLastTwenty] = useState(0);
  const [bestEverAverageTwenty, setBestEverAverageTwenty] = useState(0);

  const calculateData = (userStats) => {

    let averageTotal = 0;
    for(let i = 0; i < userStats.length; i++){
      averageTotal += userStats[i].total;
    }
    if(userStats.length > 0){
      averageTotal = averageTotal / userStats.length;
      setAverageTotal(prettyNumber(averageTotal));
    }

    let averageCurrentTwenty = 0;
    let minCurrentTwentyIndex = userStats.length - userStats.length % GROUP_SIZE;
    if(minCurrentTwentyIndex < 0){
      minCurrentTwentyIndex = 0;
    }
    for(let i = minCurrentTwentyIndex; i < userStats.length; i++){
      averageCurrentTwenty += userStats[i].total;
    }
    if(userStats.length - minCurrentTwentyIndex > 0){
      averageCurrentTwenty = averageCurrentTwenty / (userStats.length - minCurrentTwentyIndex);
      setAverageCurrentTwenty(prettyNumber(averageCurrentTwenty));
    }

    let averageLastTwenty = 0;
    let minLastTwentyIndex = userStats.length - GROUP_SIZE - userStats.length % GROUP_SIZE;
    if(minLastTwentyIndex < 0){
      minLastTwentyIndex = 0;
    }
    let maxLastTwentyIndex = minLastTwentyIndex + GROUP_SIZE;
    if(maxLastTwentyIndex > userStats.length){
      maxLastTwentyIndex = minLastTwentyIndex;
    }
    for(let i = minLastTwentyIndex; i < maxLastTwentyIndex; i++){
      averageLastTwenty += userStats[i].total;
    }
    if(maxLastTwentyIndex - minLastTwentyIndex > 0){
      averageLastTwenty = averageLastTwenty / (maxLastTwentyIndex - minLastTwentyIndex);
      setAverageLastTwenty(prettyNumber(averageLastTwenty));
    }

    let bestEverAverageTwenty = -1;
    let runs = Math.ceil(userStats.length / GROUP_SIZE);
    for(let i = 0; i < runs - 1; i++){
      let currentTestAverageTwenty = 0;
      let minBestEverTwentyIndex = GROUP_SIZE*i;
      let maxBestEverTwentyIndex = minBestEverTwentyIndex + GROUP_SIZE;
      if(maxBestEverTwentyIndex > userStats.length){
        maxBestEverTwentyIndex = userStats.length;
      }
      for(let j = minBestEverTwentyIndex; j < maxBestEverTwentyIndex; j++){
        currentTestAverageTwenty += userStats[j].total;
      }
      if(maxBestEverTwentyIndex - minBestEverTwentyIndex > 0){
        currentTestAverageTwenty = currentTestAverageTwenty / (maxBestEverTwentyIndex - minBestEverTwentyIndex);
        if(currentTestAverageTwenty < bestEverAverageTwenty || bestEverAverageTwenty < 0){
          bestEverAverageTwenty = currentTestAverageTwenty;
        }
      }
    }
    if(bestEverAverageTwenty >= 0){
      setBestEverAverageTwenty(prettyNumber(bestEverAverageTwenty));
    }
  };

  // Calculate data every time userstats changes 
  useEffect(() => {
    calculateData(props.userStats);
  }, [props.userStats]);

  return (
    <div className="user-stats">
        <p>Average Total: {averageTotal}</p>
        <p>Average Current Twenty: {averageCurrentTwenty}</p>
        <p>Average Last Twenty: {averageLastTwenty}</p>
        <p>Best Ever Average Twenty: {bestEverAverageTwenty}</p>
    </div>
  );
}

export default UserStats;