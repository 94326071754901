import './styles/allUserStats.scss';
import prettyNumber from '../../common/prettyNumber.js';

const GROUP_SIZE = 20;

const AllUserStats = (props) => {

  const calculatePages = () => {
    let allTwentyScores = [];
    let runs = Math.ceil(props?.userStats.length / GROUP_SIZE);
    for(let i = 0; i < runs; i++){
      let currentTestAverageTwenty = 0;
      let minCalculatingTwentyIndex = GROUP_SIZE*i;
      let maxCalculatingTwentyIndex = minCalculatingTwentyIndex + GROUP_SIZE;
      if(maxCalculatingTwentyIndex > props?.userStats.length){
        maxCalculatingTwentyIndex = props?.userStats.length;
      }
      for(let j = minCalculatingTwentyIndex; j < maxCalculatingTwentyIndex; j++){
        currentTestAverageTwenty += props?.userStats[j].total;
      }
      if(maxCalculatingTwentyIndex - minCalculatingTwentyIndex > 0){
        currentTestAverageTwenty = currentTestAverageTwenty / (maxCalculatingTwentyIndex - minCalculatingTwentyIndex);
        allTwentyScores.push(currentTestAverageTwenty);
      }
    }

    return allTwentyScores;
  };

  const ALL_TWENTY_SCORES = calculatePages();

  return (
    <div className="all-user-stats">
      <div className="stats-modal">
        <table border="1">
          <thead>
            <td>Attempt</td>
            <td>Ball Down On Break?</td>
            <td>Pots Missed</td>
            <td>Positions Missed</td>
            <td>Fouls/Failed Breaks</td>
            <td>Adjustments</td>
            <td>Total</td>
          </thead>
          <tbody>
            {props?.userStats?.map((val, index) => {
              return (
                <>
                  {index % GROUP_SIZE === 0 && `
                    --- Page ${Math.ceil(index / GROUP_SIZE) + 1} ---
                  `}
                  <tr>
                    <td>{index + 1}</td>
                    <td>{val.ball_down_on_break ? 'No - 1' : 'Yes - 0'}</td>
                    <td>{val.pots_missed}</td>
                    <td>{val.positions_missed}</td>
                    <td>{val.fouls}</td>
                    <td>{val.adjustments}</td>
                    <td><span class="total">{prettyNumber(val.total)}</span></td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>

        <br /><br />

        <table border="1">
          <thead>
            <td>Page</td>
            <td>Average 20</td>
          </thead>
          <tbody>
            {ALL_TWENTY_SCORES.map((val, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td><span class="total">{prettyNumber(val)}</span></td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <br />
        <br />
        <br />
        <br />
        <br />
        
      </div>
    </div>
  );
};
  
export default AllUserStats;