import Axios from 'axios';

// For https
const GET_DB_URL = 'https://pool-tracker.adventureteampro.com:444/api/get';
const SET_DB_URL = 'https://pool-tracker.adventureteampro.com:444/api/set';

// For http
// const GET_DB_URL = 'http://pool-tracker.adventureteampro.com:3001/api/get';
// const SET_DB_URL = 'http://pool-tracker.adventureteampro.com:3001/api/set';

const dbManager = {
  getAllUsers: async () => { try {
    const data = await Axios.get(`${GET_DB_URL}/users`);
    return data;
  } catch (e) { console.log("Database error: ", e); return false } },
  getUser: async (user) => { try {
    const data = await Axios.get(`${GET_DB_URL}/user/${user}`);
    return data;
  } catch (e) { console.log("Database error: ", e); return false } },
  getUserStats: async (userId) => { try {
    const data = await Axios.get(`${GET_DB_URL}/user/${userId}/stats`);
    return data;
  } catch (e) { console.log("Database error: ", e); return false } },
  saveMasterClearanceData: async (inputData) => { try {
    const data = await Axios.get(`${SET_DB_URL}/masterclearance/${inputData}`);
    return data;
  } catch (e) { console.log("Database error: ", e); return false } }
};

export default dbManager;